import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, TextField, Button, Box, LinearProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import { toast } from "react-toastify";
import axios from "axios";
import { validateMaxDiscountChange, validateMaxDiscountSubmit } from "../../helpers/inputValidation";

const IMAGE_PATH =  "/img";

const DEFAULT_STEP = 1;
const DEFAULT_IS_SCRIPT_CLICKED = false;
const DEFAULT_IS_SCRIPT_ACTIVATED = false;
const DEFAULT_MAX_DISCOUNT = 20;
const DEFAULT_REDIRECT_TO_DASHBOARD = false;

const PARAMS = window.location.search;
const SHOPIFY_DOMAIN = new URL(window.location.href).searchParams.get("shop");
const DEEP_LINK = "https://" + SHOPIFY_DOMAIN + "/admin/themes/current/editor?context=apps&activateAppId=804c0672-ed41-4841-a61e-32f7241766ed/js_embed"

const CardCustom = styled(Card)(({ theme }) => ({
    border: "1px solid #E0E0E0",
    borderRadius: "1rem",
    boxShadow: "none"
}));

const Onboarding = () => {
    const [step, setStep] = useState(DEFAULT_STEP);
    const [isScriptClicked, setIsScriptClicked] = useState(DEFAULT_IS_SCRIPT_CLICKED);
    const [isScriptActivated, setIsScriptActivated] = useState(DEFAULT_IS_SCRIPT_ACTIVATED);
    const [maxDiscount, setMaxDiscount] = useState(DEFAULT_MAX_DISCOUNT);
    const [redirectToDashboard, setRedirectToDashboard] = useState(DEFAULT_REDIRECT_TO_DASHBOARD);
    const navigate = useNavigate();

    const handleActivateScriptClick = () => {
        setIsScriptClicked(true);
    };

    const handleVerifyScriptActivationClick = async () => {
        try {
            const isScriptActivated = await axios.get("/react/merchant_onboarding_script" + PARAMS);
            if (isScriptActivated.status === 200) {
                if (isScriptActivated?.data && isScriptActivated.data === "Okay") {
                    setIsScriptActivated(true);
                } else {
                    toast.error("Error: Script is not activated. Please try again.");
                    return;
                }
            } else {
                throw new Error(isScriptActivated.status);
            }
        } catch (err) {
            toast.error("Error");
            console.error(err);
        }
    }

    const handleMaxDiscountChange = (e) => {
        const maxDiscount = validateMaxDiscountChange(e);
        setMaxDiscount(maxDiscount);
    };

    const handleActivateVandraClick = async () => {
        try {
            if (!validateMaxDiscountSubmit(maxDiscount)) {
                toast.error("Error: Please enter a max discount value between 0 and 40.");
                return;
            }
            const save = await fetch("/react/merchant_onboarding3_save" + PARAMS, {
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                method: "POST",
                body: "max_discount=" + encodeURIComponent(maxDiscount)
            });
            if (save.status === 200) {
                if (save?.ok !== undefined && save.ok) {
                    navigate("/");
                }
            } else {
                throw new Error(save.status);
            }
        } catch (err) {
            toast.error("Error");
            console.error(err);
        }
    };

    const renderStep = () => {
        if (step === 1) {
            return <>
                <div className="flex-col-left gap-xs full">
                    <small style={{ borderRadius: "1rem", padding: "2px 8px", color: "#856CF8", backgroundColor: "#F8F6FF" }}>Onboarding 1</small>
                    <h1>Thanks for Installing the Intelligent Discounts App!</h1>
                    <p>Our app is focused on driving an increase in your sales by intelligently surfacing discounts <strong>only to visitors unlikely to convert without a little extra nudge.</strong></p>
                    <p>We only get paid when we drive sales for your brand. There are no monthly fees! We earn a 5% commission on sales using one of our discount codes.</p>
                    <p>Let's get you set up. It will only take a couple of minutes to complete this process.</p>
                    <p>Please review our <a href="https://vandra.ai/terms" target="_blank" rel="noreferrer" className="link">Terms of Service</a> and click the "<strong>I Agree</strong>" button to get started.</p>
                </div>
                <div className="flex-row-left full">
                    <Button variant="contained" style={{ background: "#856CF8" }} onClick={() => setStep(step + 1)} aria-label="Agree">
                        I Agree
                    </Button>
                </div>
            </>;
        } else if (step === 2) {
            return <>
                <div className="flex-col-left gap-xs full">
                    <small style={{ borderRadius: "1rem", padding: "2px 8px", color: "#856CF8", backgroundColor: "#F8F6FF" }}>Onboarding 2</small>
                    <h1>Activate Our Script</h1>
                    <p>You will need to activate our script so we can start collecting data and intelligently surface discounts for your customers.</p>
                    <a href="https://www.loom.com/share/76e80eae4dc948d1bb19c4a3fedaec6f" target="_blank" rel="noreferrer" className="link">
                        <p>Watch this video for step-by-step instructions on activating the Vandra script in your theme.</p>
                    </a>
                    <div className="flex-col-left gap">
                        {isScriptActivated ?
                            <CardCustom style={{ border: "2px solid #856CF8", paddingLeft: "1rem", paddingRight: "2rem" }} className="flex-row-left gap">
                                <img src={`${IMAGE_PATH}/onboarding/Onboarding-Completed-Step.png`} alt="Script Activated" />
                                <p>Script Activated</p>
                            </CardCustom> :
                            <>
                                <a style={{ textDecoration: "none", color: "#000" }} href={DEEP_LINK} target="_blank" rel="noreferrer">
                                    <Button variant="contained" style={{ background: "#856CF8" }} onClick={handleActivateScriptClick} aria-label="Activate Script">
                                        Activate Script
                                    </Button>
                                </a>
                                {isScriptClicked ?
                                    <Button variant="contained" style={{ background: "#856CF8" }} onClick={handleVerifyScriptActivationClick} aria-label="Verify Script Activation">
                                        Verify Script Activation
                                    </Button> :
                                    <></>
                                }
                            </>
                        }
                    </div>
                </div>
                <div className="flex-row-left full">
                    <Button variant="contained" disabled={!isScriptActivated} style={{ color: "white", background: "#856CF8", opacity: isScriptActivated ? "" : "20%" }} onClick={() => setStep(step + 1)} aria-label="Next Step">
                        Discount
                    </Button>
                </div>
            </>;
        } else if (step === 3) {
            return <>
                <div className="flex-col-left gap-xs full">
                    <small style={{ borderRadius: "1rem", padding: "2px 8px", color: "#856CF8", backgroundColor: "#F8F6FF" }}>Onboarding 3</small>
                    <h1>Choose the Discount to Offer</h1>
                    <p>Select the percentage discount you'd like us to offer. Keep in mind that you can always adjust this percentage, if you change your mind.</p>
                    <p>Factors to consider:</p>
                    <ul>
                        <li>We only show this discount to visitors unlikely to convert without a nudge, as determined by our AI algorithm</li>
                        <li>Our algorithm incorporates user behavior, devices attributes, purchase history and more to determine whether to surface a discount</li>
                        <li>Select a discount rate that you believe will provide extra motivation to purchase while still maintaining profitability</li>
                    </ul>
                    <div className="flex-row-left gap full">
                        <p style={{ whiteSpace: "nowrap" }}>Discount Offer (%):</p>
                        <TextField variant="outlined" type="number" value={maxDiscount} className="full"
                            onKeyPress={(e) => {
                                if (e?.key === "-" || e?.key === "+") {
                                    e.preventDefault();
                                }
                            }}
                            onChange={handleMaxDiscountChange} />
                    </div>
                </div>
                <div className="flex-row-left full">
                    <Button variant="contained" style={{ color: "white", background: "#84BF7E" }} onClick={handleActivateVandraClick} aria-label="Activate Vandra">
                        Activate Vandra
                    </Button>
                </div>
            </>;
        }
    }

    return (
        <div className="flex-col-center">
            <div className="content flex-col-left gap full">
                <CardCustom style={{ position: "relative", background: "#F8F6FF", zIndex: 2 }} className="flex-col-center gap full card">
                    <img style={{ position: "absolute", bottom: -150, left: -150, zIndex: 1 }} src={`${IMAGE_PATH}/onboarding/Onboarding-Banner.png`} alt="Onboarding Banner" />
                    <h1 style={{ zIndex: 2 }}>Welcome to Vandra!</h1>
                    <p style={{ zIndex: 2 }}>Get started in just 30 seconds with our quick and easy onboarding process!</p>
                    <img style={{ position: "absolute", top: -150, right: -150, zIndex: 1 }} src={`${IMAGE_PATH}/onboarding/Onboarding-Banner.png`} alt="Onboarding Banner" />
                </CardCustom>
                <div className="flex-row-center-top gap full">
                    <CardCustom style={{ border: "1px solid #DFD9FD" }} className="flex-col-center gap two-thirds card">
                        {renderStep()}
                    </CardCustom>
                    <div className="flex-col-center one-third">
                        <div className="flex-row-center gap full">
                            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignSelf: "stretch", width: "5px" }}>
                                <div style={{ height: `${step === 1 ? "2.5%" : step === 2 ? "42.5%" : step === 3 ? "85%" : ""}`, borderRadius: `${step === 3 ? "2px 2px 2px 2px" : "2px 2px 0px 0px"}`, backgroundColor: "#856CF8" }} />
                                <div style={{ height: `${step === 1 ? "82.5%" : step === 2 ? "42.5%" : step === 3 ? "0%" : ""}`, borderRadius: "0px 0px 2px 2px", backgroundColor: "#DFD9FD" }} />
                            </Box>
                            <div className="flex-col-left gap-md">
                                <div style={{ position: "relative" }} className="flex-row-center gap full">
                                    <img src={step === 1 ? `${IMAGE_PATH}/onboarding/Onboarding-Current.png` : `${IMAGE_PATH}/onboarding/Onboarding-Completed.png`} alt="Step 1" style={{ width: "20px", height: "20px", zIndex: 1 }} />
                                    <p style={{ fontWeight: "bold", whiteSpace: "nowrap", color: step >= 1 ? "black" : "#A2A2A2" }}>Onboarding 1</p>
                                    <div style={{ position: "absolute", top: "37px", left: "9.25px", height: "102px", borderLeft: `2px solid ${step > 1 ? "#856CF8" : "#DFD9FD"}` }} />
                                </div>
                                <div style={{ position: "relative" }} className="flex-row-center gap full">
                                    <img src={step < 2 ? `${IMAGE_PATH}/onboarding/Onboarding-Upcoming.png` : step === 2 ? `${IMAGE_PATH}/onboarding/Onboarding-Current.png` : step > 2 ? `${IMAGE_PATH}/onboarding/Onboarding-Completed.png` : ""} alt="Step 2" style={{ width: "20px", height: "20px", zIndex: 1 }} />
                                    <p style={{ fontWeight: "bold", whiteSpace: "nowrap", color: step >= 2 ? "black" : "#A2A2A2" }}>Onboarding 2</p>
                                    <div style={{ position: "absolute", top: "37px", left: "9.25px", height: "102px", borderLeft: `2px solid ${step > 2 ? "#856CF8" : "#DFD9FD"}` }} />
                                </div>
                                <div className="flex-row-center gap full">
                                    <img src={step < 3 ? `${IMAGE_PATH}/onboarding/Onboarding-Upcoming.png` : step === 3 ? `${IMAGE_PATH}/onboarding/Onboarding-Current.png` : step > 3 ? `${IMAGE_PATH}/onboarding/Onboarding-Completed.png` : ""} alt="Step 3" style={{ width: "20px", height: "20px", zIndex: 1 }} />
                                    <p style={{ fontWeight: "bold", whiteSpace: "nowrap", color: step >= 3 ? "black" : "#A2A2A2" }}>Onboarding 3</p>
                                </div>
                            </div>
                        </div>
                        <img src={step === 1 ? `${IMAGE_PATH}/onboarding/Onboarding-1.png` : step === 2 ? `${IMAGE_PATH}/onboarding/Onboarding-2.png` : step === 3 ? `${IMAGE_PATH}/onboarding/Onboarding-3.png` : ""} alt={`Onboarding Step ${step}`} />
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Onboarding;